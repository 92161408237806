import React from "react";
import { TiHtml5 } from "react-icons/ti";
import { FaAws, FaCss3Alt, FaDocker } from "react-icons/fa6";
import { BiLogoPostgresql, BiLogoTailwindCss, BiLogoTypescript } from "react-icons/bi";
import { FaReact } from "react-icons/fa6";
import { IoLogoJavascript } from "react-icons/io";
import { GrMysql } from "react-icons/gr";
import { FaWordpress } from "react-icons/fa6";
import { FaNodeJs } from "react-icons/fa6";
import { RiNextjsFill } from "react-icons/ri";
import { DiMongodb, DiPostgresql, DiRedis } from "react-icons/di";

const Skill = (props) => {
  // console.log(props);
  return (
    <section id="skill" ref={props.sectionRef}>
      <div className="vertical-line"></div>
      <div className="contact workspace flex flex-col">
        <p className="text-[64px] font-bold">My Expertises</p>
        <div className="grid lg:grid-cols-5 grid-cols-3 flex-wrap w-auto m-auto justify-center mt-[4.5rem] gap-5 max-w-[860px] cursor-pointer">
          <div className="bg-[#00ADB5] group hover:text-[#00ADB5] hover:bg-white font-bold transition duration-300 text-center flex flex-col items-center rounded-lg hover:scale-105 shadow-md shadow-black hover:shadow-xl w-[110px] lg:w-[130px] h-[130px] object-cover border border-black justify-center">
            <IoLogoJavascript
              size={45}
              className="group-hover:rotate-[360deg] transition-transform duration-300 ease-in-out "
            />
            JavaScript
          </div>
          <div className="bg-[#00ADB5] group hover:text-[#00ADB5] hover:bg-white font-bold transition duration-300 text-center flex flex-col items-center rounded-lg hover:scale-105 shadow-md shadow-black hover:shadow-xl w-[110px] lg:w-[130px] h-[130px] object-cover border border-black justify-center">
            <BiLogoTypescript
              size={45}
              className="group-hover:rotate-[360deg] transition-transform duration-300 ease-in-out "
            />
            TypeScript
          </div>
          <div className="bg-[#00ADB5] group hover:text-[#00ADB5] hover:bg-white font-bold transition duration-300 text-center flex flex-col items-center rounded-lg hover:scale-105 shadow-md shadow-black hover:shadow-xl w-[110px] lg:w-[130px] h-[130px] object-cover border border-black justify-center">
            <RiNextjsFill
              size={45}
              className="group-hover:rotate-[360deg] transition-transform duration-300 ease-in-out"
            />
            NextJs
          </div>
          <div className="bg-[#00ADB5] group hover:text-[#00ADB5] hover:bg-white font-bold transition duration-300 text-center flex flex-col items-center rounded-lg hover:scale-105 shadow-md shadow-black hover:shadow-xl w-[110px] lg:w-[130px] h-[130px] object-cover border border-black justify-center">
            <FaReact
              size={45}
              className="group-hover:rotate-[360deg] transition-transform duration-300 ease-in-out"
            />
            React
          </div>

          <div className="bg-[#00ADB5] group hover:text-[#00ADB5] hover:bg-white font-bold transition duration-300 text-center flex flex-col items-center rounded-lg hover:scale-105 shadow-md shadow-black hover:shadow-xl w-[110px] lg:w-[130px] h-[130px] object-cover border border-black justify-center">
            <FaNodeJs
              size={45}
              className="group-hover:rotate-[360deg] transition-transform duration-300 ease-in-out"
            />
            NodeJs
          </div>
          <div className="bg-[#00ADB5] group hover:text-[#00ADB5] hover:bg-white font-bold transition duration-300 text-center flex flex-col items-center rounded-lg hover:scale-105 shadow-md shadow-black hover:shadow-xl w-[110px] lg:w-[130px] h-[130px] object-cover border border-black justify-center">
            <DiRedis
              size={45}
              className="group-hover:rotate-[360deg] transition-transform duration-300 ease-in-out"
            />
            Redis
          </div>
          <div className="bg-[#00ADB5] group hover:text-[#00ADB5] hover:bg-white font-bold transition duration-300 text-center flex flex-col items-center rounded-lg hover:scale-105 shadow-md shadow-black hover:shadow-xl w-[110px] lg:w-[130px] h-[130px] object-cover border border-black justify-center">
            <DiMongodb
              size={45}
              className="group-hover:rotate-[360deg] transition-transform duration-300 ease-in-out"
            />
            MongoDB
          </div>
          <div className="bg-[#00ADB5] group hover:text-[#00ADB5] hover:bg-white font-bold transition duration-300 text-center flex flex-col items-center rounded-lg hover:scale-105 shadow-md shadow-black hover:shadow-xl w-[110px] lg:w-[130px] h-[130px] object-cover border border-black justify-center">
            <BiLogoPostgresql
              size={45}
              className="group-hover:rotate-[360deg] transition-transform duration-300 ease-in-out"
            />
            Postgresql
          </div>
          <div className="bg-[#00ADB5] group hover:text-[#00ADB5] hover:bg-white font-bold transition duration-300 text-center flex flex-col items-center rounded-lg hover:scale-105 shadow-md shadow-black hover:shadow-xl w-[110px] lg:w-[130px] h-[130px] object-cover border border-black justify-center">
            <FaDocker
              size={45}
              className="group-hover:rotate-[360deg] transition-transform duration-300 ease-in-out"
            />
            Docker
          </div>
          <div className="bg-[#00ADB5] group hover:text-[#00ADB5] hover:bg-white font-bold transition duration-300 text-center flex flex-col items-center rounded-lg hover:scale-105 shadow-md shadow-black hover:shadow-xl w-[110px] lg:w-[130px] h-[130px] object-cover border border-black justify-center">
            <FaAws
              size={45}
              className="group-hover:rotate-[360deg] transition-transform duration-300 ease-in-out"
            />
            AWS
          </div>
          {/* <div className="bg-[#00ADB5] group hover:text-[#00ADB5] hover:bg-white font-bold transition duration-300 text-center flex flex-col items-center rounded-lg hover:scale-105 shadow-md shadow-black hover:shadow-xl w-[130px] object-cover border border-black justify-center h-[130px]">
            <FaNodeJs
              size={45}
              className="group-hover:rotate-[360deg] transition-transform"
            />
            NodeJs
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default Skill;
